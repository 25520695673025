<template>
  <body class="auth-wrapper container">
    <div class="all-wrapper menu-side with-pattern">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Email Verification</h3>
        <div class="buttons-w mt-2"></div>
        <br />
        <div v-if="tokenStatus === null"></div>
        <div v-else-if="tokenStatus">
          <h6>
            Your email has been verified. You can now
            <router-link to="/login">
              <small>login </small>
            </router-link>
          </h6>
        </div>
        <div v-else-if="!tokenStatus">
          <h6>
            Token is invalid or expired please request a new one
            <router-link to="/resend-verification">
              <small>Here </small>
            </router-link>
          </h6>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import { useRoute } from 'vue-router';
import { mapActions } from 'vuex';
import logo from '../../assets/img/signature-logo.png';

export default {
  data() {
    return {
      tokenverify: null,
      token: useRoute().params.token,
    };
  },
  mounted() {
    this.clearLocalState();
    this.verify();
  },
  setup() {
    const route = useRoute();
    const token = route.params.token;
    return { logo, tokenStatus: null };
  },
  methods: {
    ...mapActions(['verifyEmail', 'clearState']),
    verify() {
      this.verifyEmail(this.token).then((data) => {
        if (data) {
          this.tokenStatus = true;
        } else {
          this.tokenStatus = false;
        }
        this.$forceUpdate();
      });
      this.$forceUpdate();
    },
    clearLocalState() {
      this.clearState();
    },
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.logo {
  width: 200px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.user-form {
  width: 80%;
  margin-top: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.header-icon {
  font-size: 60px;
  color: #047bf8;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
  margin-left: -12px;
}
.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
</style>
